import Lottie from "react-lottie";
import * as durableAnimation from "images/homepage/ducky-durable.json";
import React, { useEffect, useState } from "react";

const DurableDucky: React.FC = () => {
    const [isStopped, setIsStopped] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const [screenWidth, setScreenWidth] = useState<number>(0); // Initial value can be 0
    const [duckyHeight, setDuckyHeight] = useState<number>(0); // Initial value can be 0
    const [duckyWidth, setDuckyWidth] = useState<number>(0); // Initial value can be 0

    // Update screen width on resize, only on the client-side
    useEffect(() => {
        // This will only run in the browser
        const handleResize = () => {
            const newWidth = window.innerWidth;
            if (newWidth !== screenWidth) {
                setScreenWidth(newWidth);
            }
        };

        // Set initial screen width on mount
        handleResize();
        if (screenWidth > 2559) {
            setDuckyHeight(185);
            setDuckyWidth(190);
        } else if (screenWidth > 1200) {
            setDuckyHeight(180);
            setDuckyWidth(185);
        } else {
            setDuckyHeight(180);
            setDuckyWidth(190);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screenWidth]); // Dependency array ensures it only runs if screenWidth changes

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: durableAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div style={{ pointerEvents: "none" }}>
            <Lottie
                options={defaultOptions}
                height={duckyHeight}
                width={duckyWidth}
                isStopped={isStopped}
                isPaused={isPaused}
            />
        </div>
    );
};

export default DurableDucky;
