import React from "react";
import Redirect from "components/Redirect";

import { isIOS } from "react-device-detect";

const Rate: React.FC = () => {
    let redirectURL =
        "https://play.google.com/store/apps/details?id=io.ente.auth";
    if (isIOS) {
        redirectURL = "https://apps.apple.com/us/app/ente-auth/id6444121398";
    }
    return <Redirect to={redirectURL} />;
};

export default Rate;
