import React from "react";
import styled from "styled-components";

interface ToggleProps {
    isOn: boolean;
    onLabel: string;
    offLabel: string;
    onToggle: () => void;
}

const Toggle: React.FC<ToggleProps> = ({
    isOn,
    onLabel,
    offLabel,
    onToggle,
}) => {
    // To implement the toggle, we use three elements
    //
    // [onLabel] <input type="checkbox"> [offLabel]
    //
    // - Depending on the state of the checkbox, either of the two labels is
    //   highlighted.
    // - The checkbox itself is styled to look like a "switch" using CSS.

    return (
        <div className="d-flex justify-content-center gap-3 align-items-center">
            <div className={isOn ? "text-muted" : "text-body"}>{offLabel}</div>
            <ToggleInput
                type="checkbox"
                role="switch"
                aria-label="Annual"
                aria-checked={isOn}
                checked={isOn}
                onChange={onToggle}
            />
            <div className={isOn ? "text-body" : "text-muted"}>{onLabel}</div>
        </div>
    );
};

export default Toggle;

/**
 * Remove default styling from an `input[type="checkbox"]`, then add back a
 * switch-like appearance.
 */
const ToggleInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    height: 32px;
    width: 52px;
    border-radius: 16px;
    display: inline-block;
    position: relative;
    margin: 0;

    border: 2px solid rgba(var(--color-muted-rgb), 0.2);
    background: rgba(var(--color-muted-rgb), 0.8);

    &&:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: white;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
    }

    &&:checked {
        background: var(--color-primary-500);
    }

    &&:checked {
        border-color: var(--color-primary-400);
    }

    &&:checked:after {
        transform: translatex(20px);
    }
`;
