import BasicSection from "components/BasicSection";
import Layout from "components/Layout";
import { PageProps } from "gatsby";
import React, { useState } from "react";
import {
    ArrowsPointingInIcon,
    CurrencyDollarIcon,
    LockClosedIcon,
    ShieldCheckIcon,
    BookOpenIcon,
    CloudArrowUpIcon,
    HeartIcon,
    LinkIcon,
    ArrowsUpDownIcon,
    FolderArrowDownIcon,
    ChatBubbleBottomCenterIcon,
    MagnifyingGlassIcon,
    EyeSlashIcon,
    QuestionMarkCircleIcon,
    UserGroupIcon,
    EllipsisHorizontalCircleIcon,
    SunIcon,
    CakeIcon,
    FingerPrintIcon,
    BoltIcon,
} from "@heroicons/react/24/outline";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

const FeaturesPage: React.FC<PageProps> = ({ path }) => {
    return (
        <Layout title="Ente - Features" path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>Features</BasicSection.Heading>
                </BasicSection.Header>

                <BasicSection.BodyElevated>
                    <FeatureList />
                </BasicSection.BodyElevated>
            </BasicSection>
        </Layout>
    );
};

export default FeaturesPage;

const features = [
    {
        title: "End-to-end encryption",
        description:
            'All of your photos, and their metadata on Ente are stored end-to-end encrypted. So unlike traditional cloud storage, with Ente, only you can view your data.<br/><br/>Our encryption protocols have been audited and the details of our architecture have been published <a href="/architecture">here</a>.',
        slug: "lock",
    },
    {
        title: "3 replicas",
        description:
            'Ente preserves your encrypted photos to 3 different clouds, in 3 different locations, including an underground fallout shelter. This guarantees that your data is as safe as it can possibly be.<br/><br/>The technical details of our data replication protocols have been published <a href="/reliability">here</a>.',
        slug: "shield",
    },
    {
        title: "Cross platform",
        description:
            'Ente has apps for Android, iOS, Linux, Mac, Windows and the web.<br/><br/>All our apps are <a href="https://github.com/ente-io/">open source</a>.',
        slug: "open",
    },
    {
        title: "Background uploads",
        description:
            "Ente backs up data in the background, across all platforms. Any new photos you click are automatically encrypted and uploaded to our servers.",
        slug: "upload",
    },
    {
        title: "Family plans",
        description:
            "You can share your subscription with 5 other family members, at no extra cost.<br/><br/>Only your storage is shared, not your data. Each member will receive their own private space.",
        slug: "money",
    },
    {
        title: "Search",
        description:
            "Ente uses on-device AI to recognise faces and key elements in a photo, so you can search through your entire library using natural language search. <br/><br/>Heirarchical Search allows you to find the exact photo your are looking for",
        slug: "search",
    },
    {
        title: "Album sharing",
        description:
            "Ente offers end-to-end encrypted sharing for your albums. If your partner is on Ente, you can share your entire library with them.<br/><br/>Any new photo you add will instantly be accessible on their devices.",
        slug: "share",
    },
    {
        title: "Link sharing",
        description:
            "You can share links to your albums, that are end-to-end encrypted. Viewers don't need an app or account.<br/><br/>You can protect your links with a password, and configure them to expire after some time.<br/><br/>Here is a <a href='https://albums.ente.io/?t=CsuRcc4k#37fb731a4915aff5adba2cb25d9f4aa2ea13e1e5952ec27233dce5033c28af7a'>sample album</a>.",
        slug: "link",
    },
    {
        title: "Collaboration",
        description:
            "You can let your loved ones add photos to your albums.<br/><br/>If they aren't on Ente, you can share a link with them, and allow them to upload photos to it. So you can easily collect photos in original quality at the end of a trip or event.<br/><br/>Everything, end-to-end encrypted.",
        slug: "collaborate",
    },
    {
        title: "Easy Import",
        description:
            "We have a powerful <a href='/download/desktop'>desktop app</a> that lets you import data from other providers.<br/><br/>If you need help moving your data, write to team@ente.io, and we'll be there.",
        slug: "import",
    },
    {
        title: "Easy Export",
        description:
            'We respect your right to data portability. Our <a href="/download/desktop">desktop app</a> lets you incrementally export your data, with a single click.',
        slug: "export",
    },
    {
        title: "Memories",
        description:
            "You can relive your memories from previous years, through stories Ente curates for you.<br/><br/>You can easily spread the cheer by sharing them with your loved ones.",
        slug: "memories",
    },
    {
        title: "Hidden Photos",
        description:
            "You can hide memories that are close to your heart.<br/><br/>These hidden items are locked behind your lock-screen and can only be accessed once you authenticate yourself.",
        slug: "hidden",
    },
    {
        title: "Descriptions",
        description:
            "You can attach descriptions and tags to your photos, that are also preserved end-to-end encrypted.<br/><br/>You can search through your library using these keywords.",
        slug: "description",
    },
    {
        title: "Additional Security",
        description:
            "You can turn on two-factor authentication to increase your account's security.<br/><br/>You can also setup a lock-screen to make sure only you can view your gallery.",
        slug: "2fa",
    },
    {
        title: "Free Device Space",
        description:
            "You can free up your device's space by clearing files that have already been backed up to Ente.<br/><br/>All it takes is a single click.",
        slug: "free",
    },
    {
        title: "Dark & Light Themes",
        description:
            "Ente's mobile apps come packed with both dark and light themes. You can choose the mode that will make your photos pop.",
        slug: "theme",
    },
    {
        title: "Support",
        description:
            'We take pride in offering human support.<br/><br/>If you need help, reach out to <a href="mailto:support@ente.io">support@ente.io</a>, and one of us will be there to assist you.',
        slug: "support",
    },
    {
        title: "Community",
        description:
            "We are building Ente with a wonderful community of like-minded folks.<br/><br/><a href='/about#community'>Join us!</a>",
        slug: "community",
    },
    {
        title: "and there's more...",
        description:
            'We are continuously listening to feedback and improving the product. You can find our public roadmap <a href="https://github.com/ente-io/ente/discussions/categories/feature-requests">here</a>.',
        slug: "more",
    },
];

const FeatureList: React.FC = () => {
    return (
        <Container>
            <Row xs={1} md={2}>
                {features.map((feature) => (
                    <FeatureCard
                        key={feature.slug}
                        title={feature.title}
                        description={feature.description}
                        slug={feature.slug}
                    />
                ))}
            </Row>
        </Container>
    );
};

const FeatureCard: React.FC<FeatureProps> = ({ title, description, slug }) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <Col
            key={title}
            className="my-3"
            onMouseEnter={() => {
                return setIsHovering(true);
            }}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div
                className={
                    "card feature-card" +
                    (isHovering ? " feature-card-hovered" : "")
                }
                style={{ height: "100%" }}
            >
                <CardContents className="card-body mx-3 my-2 my-md-3">
                    <h5
                        className={
                            "card-title mt-1" +
                            (!isHovering ? "" : " text-bold")
                        }
                    >
                        {title}
                    </h5>
                    <div
                        className={
                            "card-text " +
                            (isHovering ? "" : "text-muted ") +
                            "mt-md-3"
                        }
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </CardContents>
                <CardIcon {...{ slug, isFocussed: isHovering }} />
            </div>
        </Col>
    );
};

interface FeatureProps {
    title: string;
    description: string;
    slug: string;
}

const CardContents = styled.div`
    z-index: 1;
`;

const CardIconContainer = styled.div`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    opacity: 17%;

    width: 24px;
    height: 24px;

    /* On the Bootstrap sm breakpoint, increase the icon size */
    @media (min-width: 576px) {
        width: 36px;
        height: 36px;
    }
`;

const CardIcon: React.FC<{ slug: string; isFocussed: boolean }> = ({
    slug,
    isFocussed,
}) => {
    const icon = getIcon(slug);

    return <CardIconContainer className="d-flex">{icon}</CardIconContainer>;
};

const getIcon = (slug: string) => {
    switch (slug) {
        case "lock":
            return <LockClosedIcon />;
        case "shield":
            return <ShieldCheckIcon />;
        case "open":
            return <BookOpenIcon />;
        case "upload":
            return <CloudArrowUpIcon />;
        case "share":
            return <UserGroupIcon />;
        case "link":
            return <LinkIcon />;
        case "collaborate":
            return <ArrowsPointingInIcon />;
        case "money":
            return <CurrencyDollarIcon />;
        case "import":
            return <ArrowsUpDownIcon />;
        case "export":
            return <FolderArrowDownIcon />;
        case "hidden":
            return <EyeSlashIcon />;
        case "description":
            return <ChatBubbleBottomCenterIcon />;
        case "search":
            return <MagnifyingGlassIcon />;
        case "support":
            return <QuestionMarkCircleIcon />;
        case "community":
            return <HeartIcon />;
        case "more":
            return <EllipsisHorizontalCircleIcon />;
        case "theme":
            return <SunIcon />;
        case "2fa":
            return <FingerPrintIcon />;
        case "memories":
            return <CakeIcon />;
        case "free":
            return <BoltIcon />;
    }
};
