import * as React from "react";
import * as links from "../../components/footer-social-media";

/*
 * SVGs from https://icons8.com/
 *
 * We are not using react-icons here because it doesn't provide flat or gradient
 * color filled icons which are required here in the design.
 */

const DiscordSVG = (
    // https://icons8.com/icon/30888/discord
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0,0,256,256"
    >
        <g
            fill="#8c9eff"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="1"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            strokeDasharray=""
            strokeDashoffset="0"
            fontFamily="none"
            fontWeight="none"
            fontSize="none"
            textAnchor="none"
            style={{ mixBlendMode: "normal" }}
        >
            <g transform="scale(5.12,5.12)">
                <path d="M41.625,10.76953c-3.98047,-3.20313 -10.27734,-3.74609 -10.54687,-3.76563c-0.41797,-0.03516 -0.81641,0.19922 -0.98828,0.58594c-0.01562,0.02344 -0.15234,0.33984 -0.30469,0.83203c2.63281,0.44531 5.86719,1.33984 8.79297,3.15625c0.46875,0.28906 0.61328,0.90625 0.32422,1.375c-0.19141,0.30859 -0.51562,0.47656 -0.85156,0.47656c-0.17969,0 -0.36328,-0.05078 -0.52734,-0.15234c-5.03125,-3.12109 -11.3125,-3.27734 -12.52344,-3.27734c-1.21094,0 -7.49609,0.15625 -12.52344,3.27734c-0.46875,0.29297 -1.08594,0.14844 -1.375,-0.32031c-0.29297,-0.47266 -0.14844,-1.08594 0.32031,-1.37891c2.92578,-1.8125 6.16016,-2.71094 8.79297,-3.15234c-0.15234,-0.49609 -0.28906,-0.80859 -0.30078,-0.83594c-0.17578,-0.38672 -0.57031,-0.62891 -0.99219,-0.58594c-0.26953,0.01953 -6.56641,0.5625 -10.60156,3.80859c-2.10547,1.94922 -6.32031,13.33984 -6.32031,23.1875c0,0.17578 0.04688,0.34375 0.13281,0.49609c2.90625,5.10938 10.83984,6.44531 12.64844,6.50391c0.00781,0 0.01953,0 0.03125,0c0.32031,0 0.62109,-0.15234 0.80859,-0.41016l1.82813,-2.51562c-4.93359,-1.27344 -7.45312,-3.4375 -7.59766,-3.56641c-0.41406,-0.36328 -0.45312,-0.99609 -0.08594,-1.41016c0.36328,-0.41406 0.99609,-0.45312 1.41016,-0.08984c0.05859,0.05469 4.69922,3.99219 13.82422,3.99219c9.14063,0 13.78125,-3.95312 13.82813,-3.99219c0.41406,-0.35937 1.04297,-0.32422 1.41016,0.09375c0.36328,0.41406 0.32422,1.04297 -0.08984,1.40625c-0.14453,0.12891 -2.66406,2.29297 -7.59766,3.56641l1.82813,2.51563c0.1875,0.25781 0.48828,0.41016 0.80859,0.41016c0.01172,0 0.02344,0 0.03125,0c1.80859,-0.05859 9.74219,-1.39453 12.64844,-6.50391c0.08594,-0.15234 0.13281,-0.32031 0.13281,-0.49609c0,-9.84766 -4.21484,-21.23828 -6.375,-23.23047zM18.5,30c-1.93359,0 -3.5,-1.78906 -3.5,-4c0,-2.21094 1.56641,-4 3.5,-4c1.93359,0 3.5,1.78906 3.5,4c0,2.21094 -1.56641,4 -3.5,4zM31.5,30c-1.93359,0 -3.5,-1.78906 -3.5,-4c0,-2.21094 1.56641,-4 3.5,-4c1.93359,0 3.5,1.78906 3.5,4c0,2.21094 -1.56641,4 -3.5,4z"></path>
            </g>
        </g>
    </svg>
);

const FigmaSVG = (
    // https://icons8.com/icon/zfHRZ6i1Wg0U/figma
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="90"
        height="90"
        viewBox="0 0 48 48"
    >
        <path
            fill="#e64a19"
            d="M26,17h-8c-3.866,0-7-3.134-7-7v0c0-3.866,3.134-7,7-7h8V17z"
        ></path>
        <path
            fill="#7c4dff"
            d="M25,31h-7c-3.866,0-7-3.134-7-7v0c0-3.866,3.134-7,7-7h7V31z"
        ></path>
        <path
            fill="#66bb6a"
            d="M18,45L18,45c-3.866,0-7-3.134-7-7v0c0-3.866,3.134-7,7-7h7v7C25,41.866,21.866,45,18,45z"
        ></path>
        <path
            fill="#ff7043"
            d="M32,17h-7V3h7c3.866,0,7,3.134,7,7v0C39,13.866,35.866,17,32,17z"
        ></path>
        <circle cx="32" cy="24" r="7" fill="#29b6f6"></circle>
    </svg>
);

const GithubSVG = (
    // https://icons8.com/icon/106562/github
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 24 24"
    >
        <path d="M10.9,2.1c-4.6,0.5-8.3,4.2-8.8,8.7c-0.5,4.7,2.2,8.9,6.3,10.5C8.7,21.4,9,21.2,9,20.8v-1.6c0,0-0.4,0.1-0.9,0.1 c-1.4,0-2-1.2-2.1-1.9c-0.1-0.4-0.3-0.7-0.6-1C5.1,16.3,5,16.3,5,16.2C5,16,5.3,16,5.4,16c0.6,0,1.1,0.7,1.3,1c0.5,0.8,1.1,1,1.4,1 c0.4,0,0.7-0.1,0.9-0.2c0.1-0.7,0.4-1.4,1-1.8c-2.3-0.5-4-1.8-4-4c0-1.1,0.5-2.2,1.2-3C7.1,8.8,7,8.3,7,7.6C7,7.2,7,6.6,7.3,6 c0,0,1.4,0,2.8,1.3C10.6,7.1,11.3,7,12,7s1.4,0.1,2,0.3C15.3,6,16.8,6,16.8,6C17,6.6,17,7.2,17,7.6c0,0.8-0.1,1.2-0.2,1.4 c0.7,0.8,1.2,1.8,1.2,3c0,2.2-1.7,3.5-4,4c0.6,0.5,1,1.4,1,2.3v2.6c0,0.3,0.3,0.6,0.7,0.5c3.7-1.5,6.3-5.1,6.3-9.3 C22,6.1,16.9,1.4,10.9,2.1z"></path>
    </svg>
);

const TwitterSVG = (
    // https://icons8.com/icon/fJp7hepMryiw/twitterx
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 50 50"
    >
        <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
    </svg>
);

const MastodonSVG = (
    <svg
        width="80"
        height="80"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M60.7539 14.3904C59.8143 7.40642 53.7273 1.90257 46.5117 0.836066C45.2943 0.655854 40.6819 0 29.9973 0H29.9175C19.2299 0 16.937 0.655854 15.7196 0.836066C8.70488 1.87302 2.29885 6.81852 0.744617 13.8852C-0.00294988 17.3654 -0.0827298 21.2237 0.0561464 24.7629C0.254119 29.8384 0.292531 34.905 0.753482 39.9598C1.07215 43.3175 1.62806 46.6484 2.41704 49.9276C3.89445 55.9839 9.87499 61.0239 15.7344 63.0801C22.0077 65.2244 28.7542 65.5804 35.2184 64.1082C35.9295 63.9428 36.6318 63.7508 37.3252 63.5321C38.8971 63.0329 40.738 62.4745 42.0913 61.4937C42.1099 61.4799 42.1251 61.4621 42.1358 61.4417C42.1466 61.4212 42.1526 61.3986 42.1534 61.3755V56.4773C42.153 56.4557 42.1479 56.4345 42.1383 56.4151C42.1287 56.3958 42.1149 56.3788 42.0979 56.3655C42.0809 56.3522 42.0611 56.3429 42.04 56.3382C42.019 56.3335 41.9971 56.3336 41.9761 56.3384C37.8345 57.3276 33.5905 57.8234 29.3324 57.8156C22.0045 57.8156 20.0336 54.3384 19.4693 52.8908C19.0156 51.6397 18.7275 50.3346 18.6124 49.0088C18.6112 48.9866 18.6153 48.9643 18.6243 48.9439C18.6333 48.9236 18.647 48.9056 18.6643 48.8915C18.6816 48.8774 18.7019 48.8675 18.7237 48.8628C18.7455 48.858 18.7681 48.8585 18.7897 48.8641C22.8622 49.8465 27.037 50.3423 31.2265 50.3412C32.234 50.3412 33.2387 50.3412 34.2463 50.3146C38.4598 50.1964 42.9009 49.9808 47.0465 49.1713C47.1499 49.1506 47.2534 49.1329 47.342 49.1063C53.881 47.8507 60.1038 43.9097 60.7362 33.9301C60.7598 33.5372 60.8189 29.8148 60.8189 29.4071C60.8218 28.0215 61.2651 19.5781 60.7539 14.3904Z"
            fill="url(#paint0_linear_89_8)"
        />
        <path
            d="M50.3943 22.237V39.5876H43.5185V22.7481C43.5185 19.2029 42.0411 17.3949 39.036 17.3949C35.7325 17.3949 34.0778 19.5338 34.0778 23.7585V32.9759H27.2434V23.7585C27.2434 19.5338 25.5857 17.3949 22.2822 17.3949C19.2949 17.3949 17.8027 19.2029 17.8027 22.7481V39.5876H10.9298V22.237C10.9298 18.6918 11.835 15.8754 13.6453 13.7877C15.5128 11.7049 17.9623 10.6355 21.0028 10.6355C24.522 10.6355 27.1813 11.9885 28.9542 14.6917L30.665 17.5633L32.3788 14.6917C34.1517 11.9885 36.811 10.6355 40.3243 10.6355C43.3619 10.6355 45.8114 11.7049 47.6847 13.7877C49.4931 15.8734 50.3963 18.6899 50.3943 22.237Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_89_8"
                x1="30.5"
                y1="0"
                x2="30.5"
                y2="65"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#6364FF" />
                <stop offset="1" stop-color="#563ACC" />
            </linearGradient>
        </defs>
    </svg>
);

const MatrixSVG = (
    // https://icons8.com/icon/uWgXc4LrgneZ/matrix
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 50 50"
    >
        <path d="M 5 5 A 1.0001 1.0001 0 0 0 4 6 L 4 44 A 1.0001 1.0001 0 0 0 5 45 L 8 45 A 1.0001 1.0001 0 1 0 8 43 L 6 43 L 6 7 L 8 7 A 1.0001 1.0001 0 1 0 8 5 L 5 5 z M 42 5 A 1.0001 1.0001 0 1 0 42 7 L 44 7 L 44 43 L 42 43 A 1.0001 1.0001 0 1 0 42 45 L 45 45 A 1.0001 1.0001 0 0 0 46 44 L 46 6 A 1.0001 1.0001 0 0 0 45 5 L 42 5 z M 31.074219 17.509766 C 29.975744 17.487506 28.868391 17.760297 27.978516 18.373047 C 27.407516 18.767047 26.915609 19.272813 26.349609 19.757812 C 25.488609 18.039813 23.929344 17.580781 22.152344 17.550781 C 20.351344 17.519781 18.920922 18.341797 17.669922 19.841797 L 17.669922 18 L 14 18 L 14 32 L 17.664062 32 C 17.664062 32 17.657969 26.766016 17.667969 24.166016 C 17.669969 23.704016 17.689203 23.23225 17.783203 22.78125 C 18.073203 21.39225 19.538031 20.534437 20.957031 20.648438 C 22.309031 20.757437 23.100016 21.495656 23.166016 23.097656 C 23.177016 23.376656 23.166016 32 23.166016 32 L 26.832031 32 L 26.832031 24.228516 C 26.838031 23.629516 26.901875 23.0175 27.046875 22.4375 C 27.372875 21.1375 28.590531 20.49825 30.019531 20.65625 C 31.279531 20.79525 32.239031 21.474609 32.332031 22.849609 L 32.332031 32 L 36 32 L 36 22 C 36 21 35.746359 20.490859 35.443359 19.880859 C 34.710859 18.405234 32.90501 17.546865 31.074219 17.509766 z"></path>
    </svg>
);

const RedditSVG = (
    // https://icons8.com/icon/0KkWdgzdvJVb/reddit
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0,0,256,256"
    >
        <g transform="translate(-20.48,-20.48) scale(1.16,1.16)">
            <g
                fill="#f74200"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style={{ mixBlendMode: "normal" }}
            >
                <g transform="scale(4,4)">
                    <path d="M32,10c-12.15,0 -22,9.85 -22,22c0,12.15 9.85,22 22,22c12.15,0 22,-9.85 22,-22c0,-12.15 -9.85,-22 -22,-22zM33.5293,18.78711c0.08716,-0.01584 0.17803,-0.01464 0.26953,0.00586l5.39258,1.07617c0.344,-0.601 0.96811,-1.00517 1.66211,-1.07617c1.209,-0.131 2.29087,0.74317 2.42188,1.95117c0.131,1.209 -0.74317,2.29283 -1.95117,2.42383c-1.209,0.131 -2.29283,-0.74217 -2.42383,-1.95117l-4.70703,-0.99023l-1.42773,6.86328c3.024,0.066 5.9657,1.00103 8.4707,2.70703c0.568,-0.541 1.31847,-0.86558 2.10547,-0.89258c1.777,-0.06 3.26408,1.3237 3.33008,3.0957c0.022,1.236 -0.67234,2.37278 -1.77734,2.92578c0.022,0.322 0.022,0.64575 0,0.96875c0,4.933 -5.74222,8.93555 -12.82422,8.93555c-7.082,0 -12.82422,-4.00855 -12.82422,-8.93555c-0.022,-0.323 -0.022,-0.64575 0,-0.96875c-0.323,-0.142 -0.61228,-0.34508 -0.86328,-0.58008c-1.296,-1.214 -1.35658,-3.24806 -0.14258,-4.53906c1.214,-1.296 3.24806,-1.35658 4.53906,-0.14258c2.537,-1.717 5.51808,-2.65803 8.58008,-2.70703l1.625,-7.63281v-0.01172c0.0615,-0.279 0.28345,-0.47786 0.54492,-0.52539zM26.87305,32c-1.216,0 -2.20117,0.98517 -2.20117,2.20117c0,1.216 0.98617,2.20117 2.20117,2.20117c1.216,0 2.20117,-0.98617 2.20117,-2.20117c0,-1.216 -0.98617,-2.20117 -2.20117,-2.20117zM37.0625,32c-1.215,0 -2.20017,0.98517 -2.20117,2.20117c0,1.216 0.98617,2.20117 2.20117,2.20117c1.216,0 2.20117,-0.98617 2.20117,-2.20117c0,-1.216 -0.98617,-2.20117 -2.20117,-2.20117zM27.03906,39.2793c-0.13338,0 -0.26745,0.04476 -0.37695,0.13477c-0.252,0.208 -0.29003,0.58589 -0.08203,0.83789c1.564,1.176 3.4795,1.77731 5.4375,1.69531c1.958,0.082 3.87155,-0.51931 5.43555,-1.69531v0.08789c0.241,-0.236 0.24672,-0.62814 0.01172,-0.86914c-0.235,-0.241 -0.63009,-0.24672 -0.87109,-0.01172c-1.34,0.957 -2.95961,1.42684 -4.59961,1.33984c-1.64,0.076 -3.25317,-0.41677 -4.57617,-1.38477c-0.112,-0.09 -0.24553,-0.13477 -0.37891,-0.13477z"></path>
                </g>
            </g>
        </g>
    </svg>
);

const LinkedInSVG = (
    // https://icons8.com/icon/13930/linkedin
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 48 48"
    >
        <path
            fill="#0288D1"
            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
        ></path>
        <path
            fill="#FFF"
            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
        ></path>
    </svg>
);

const InstagramSVG = (
    // https://icons8.com/icon/119026/instagram
    <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 48 48"
    >
        <radialGradient
            id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1"
            cx="19.38"
            cy="42.035"
            r="44.899"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#fd5"></stop>
            <stop offset=".328" stopColor="#ff543f"></stop>
            <stop offset=".348" stopColor="#fc5245"></stop>
            <stop offset=".504" stopColor="#e64771"></stop>
            <stop offset=".643" stopColor="#d53e91"></stop>
            <stop offset=".761" stopColor="#cc39a4"></stop>
            <stop offset=".841" stopColor="#c837ab"></stop>
        </radialGradient>
        <path
            fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
            d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
        ></path>
        <radialGradient
            id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2"
            cx="11.786"
            cy="5.54"
            r="29.813"
            gradientTransform="matrix(1 0 0 .6663 0 1.849)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#4168c9"></stop>
            <stop offset=".999" stopColor="#4168c9" stopOpacity="0"></stop>
        </radialGradient>
        <path
            fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
            d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"
        ></path>
        <path
            fill="#fff"
            d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
        ></path>
        <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
        <path
            fill="#fff"
            d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
        ></path>
    </svg>
);

export const ArrowUpRightSVG = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
    >
        <path
            d="M10.8328 31.0745L13.4255 33.6673L28.9818 18.1109L31.5746 20.7036L34.1673 18.1109L31.5746 15.5182L34.1673 12.9255L31.5746 10.3327L28.9818 12.9255L26.3891 10.3327L23.7964 12.9255L26.3891 15.5182L10.8328 31.0745ZM18.6109 12.9255L21.2037 10.3327L23.7964 12.9255L21.2037 15.5182L18.6109 12.9255ZM18.6109 12.9255L16.0182 15.5182L13.4255 12.9255L16.0182 10.3327L18.6109 12.9255ZM31.5746 25.8891L34.1673 23.2964L31.5746 20.7036L28.9818 23.2964L31.5746 25.8891ZM31.5746 25.8891L28.9818 28.4818L31.5746 31.0745L34.1673 28.4818L31.5746 25.8891Z"
            fill="black"
        />
    </svg>
);

// logoInfo for BodySection2 in /community page

/**
 * An icon for one of our community presences, alongwith the URL for it.
 */
export interface CommunityIcon {
    /** ID for each Community Icon */
    id: string;
    /** The SVG icon */
    icon: React.JSX.Element;
    /** The community URL */
    url: string;
    /**
     * The background color or gradient that goes well with this community's
     * icon.
     */
    hoverBackground: string;
}

/**
 * The {@link CommunityIcon} for our various communities.
 *
 * The first three of these have predefined semantics. e.g. the first entry in
 * this array is blindly assumed to be the icon information for Discord.
 */
export const communityIcons: CommunityIcon[] = [
    {
        id: "Discord",
        icon: DiscordSVG,
        url: links.discordDirectURL,
        hoverBackground: "linear-gradient(204deg, #5865f2 0%, #010fad 96.02%)",
    },
    {
        id: "Figma",
        icon: FigmaSVG,
        url: links.figmaURL,
        hoverBackground:
            "linear-gradient(203deg, #0acf83 1.32%, #06f155 93.77%)",
    },
    {
        id: "GitHub",
        icon: GithubSVG,
        url: links.githubURL,
        hoverBackground:
            "linear-gradient(200deg, #61ffe3 3.66%, #00bf8d 99.15%)",
    },
    {
        id: "Twitter",
        icon: TwitterSVG,
        url: links.twitterURL,
        hoverBackground: "linear-gradient(204deg, #CDC8C8 2%, #9B8686 97%)",
    },
    {
        id: "Mastodon",
        icon: MastodonSVG,
        url: links.mastodonURL,
        hoverBackground:
            "linear-gradient(201deg, #F8CB5A 2.87%, #A57700 96.71%) ",
    },
    {
        id: "Matrix",
        icon: MatrixSVG,
        url: links.matrixDirectURL,
        hoverBackground:
            "linear-gradient(204deg, #90FFE4 3.66%, #00634B 99.15%)",
    },
    {
        id: "Reddit",
        icon: RedditSVG,
        url: links.redditURL,
        hoverBackground:
            "linear-gradient(202deg, #FF8152 1.44%, #FF4500 98.28%)",
    },
    {
        id: "Linkedin",
        icon: LinkedInSVG,
        url: links.linkedinURL,
        hoverBackground:
            "linear-gradient(200deg, #00A1FF 0.76%, #0068C9 95.09%)",
    },
    {
        id: "Instagram",
        icon: InstagramSVG,
        url: links.instaURL,
        hoverBackground:
            "linear-gradient(200deg, #FFB230 0.33%, #DA21B0 97.38%)",
    },
];
