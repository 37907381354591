import React from "react";
import styled, { keyframes } from "styled-components";

interface IProps {
    text: string;
}

const shine = keyframes`
  0% {
    background-position: 0;
  }
  60% {
    background-position: 100px;
  }
  100% {
    background-position: 180px;
  }
`;

const StyledText = styled.p`
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    color: "#111111";
    @media (min-width: 1200px) {
        font-size: 15px;
        margin-top: -7%;
    }

    transform: translate(-50%, -50%);
    padding: 12px 48px;
    background: "linear-gradient(to right, #979595 0, #5a1eb3 10%, #979595 20%)";
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${shine} 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    white-space: nowrap;
`;

export default function TextShine(props: IProps) {
    return <StyledText>{props.text}</StyledText>;
}
