exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-auth-index-tsx": () => import("./../../../src/pages/auth/index.tsx" /* webpackChunkName: "component---src-pages-auth-index-tsx" */),
  "component---src-pages-auth-ph-tsx": () => import("./../../../src/pages/auth-ph.tsx" /* webpackChunkName: "component---src-pages-auth-ph-tsx" */),
  "component---src-pages-auth-rate-tsx": () => import("./../../../src/pages/auth/rate.tsx" /* webpackChunkName: "component---src-pages-auth-rate-tsx" */),
  "component---src-pages-blackfriday-tsx": () => import("./../../../src/pages/blackfriday.tsx" /* webpackChunkName: "component---src-pages-blackfriday-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-discord-tsx": () => import("./../../../src/pages/discord.tsx" /* webpackChunkName: "component---src-pages-discord-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download/[...].tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-encryption-tsx": () => import("./../../../src/pages/encryption.tsx" /* webpackChunkName: "component---src-pages-encryption-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-github-tsx": () => import("./../../../src/pages/github.tsx" /* webpackChunkName: "component---src-pages-github-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intern-tsx": () => import("./../../../src/pages/intern.tsx" /* webpackChunkName: "component---src-pages-intern-tsx" */),
  "component---src-pages-matrix-tsx": () => import("./../../../src/pages/matrix.tsx" /* webpackChunkName: "component---src-pages-matrix-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-rate-tsx": () => import("./../../../src/pages/rate.tsx" /* webpackChunkName: "component---src-pages-rate-tsx" */),
  "component---src-pages-share-tsx": () => import("./../../../src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */),
  "component---src-pages-translate-tsx": () => import("./../../../src/pages/translate.tsx" /* webpackChunkName: "component---src-pages-translate-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-comparison-template-tsx": () => import("./../../../src/templates/comparison-template.tsx" /* webpackChunkName: "component---src-templates-comparison-template-tsx" */),
  "component---src-templates-faq-template-tsx": () => import("./../../../src/templates/faq-template.tsx" /* webpackChunkName: "component---src-templates-faq-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

